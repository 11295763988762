import Immutable from 'immutable';
import { diffString } from 'json-diff';

const makeBulkUpdateRequest = (
  currentObjects,
  originalObjects,
  objectType,
  getItemId = x => x.uid
) => ({
  objects: Object.values(currentObjects).reduce((memo, currentItem) => {
    const originalItem = originalObjects[getItemId(currentItem)];
    if (!originalItem) {
      return memo;
    }
    const originalItemIm = Immutable.fromJS(originalItem);
    const currentItemIm = Immutable.fromJS(currentItem);
    const mergedItemIm = originalItemIm.merge(currentItemIm);

    if (!Immutable.is(originalItemIm, mergedItemIm)) {
      if (global.GEODISC_SIMULATION_SAVING_LOGS_ENABLED) {
        // eslint-disable-next-line
        console.log('===', objectType, getItemId(currentItem));
        // eslint-disable-next-line
        console.log(diffString(originalItem, currentItem, {}));
      }
      return [...memo, currentItem];
    }

    return memo;
  }, []),
  deleted_objects: [],
});

export default makeBulkUpdateRequest;
