import { point } from '@turf/helpers';
import { geoToH3 } from 'h3-js';

import { call, put, take, select } from 'redux-saga/effects';
import { api$CommuteOffer as api } from 'api';
import { sleep } from 'utils/async';
import { nearestFeatureEx } from 'utils/nearest-feature';

import { normalizeCommuteOffer } from 'utils/CommuteOffer';
import { commuteOffer$FilterVehicles } from 'utils/CommuteOffer';

import * as actions from 'modules/commuteOffer/actions';
import * as uiActions from 'modules/ui/actions';

import { detailedRouteSourceSelector } from 'modules/maps/selectors';

import { currentProjectConfigSelector } from 'modules/user/selectors';

import {
  commuteOfferCurrentDataSelector,
  allVehiclesSelector,
} from 'modules/commuteOffer/selectors';

// import { fetchAllRoutesHandler } from './fetchAllRoutes';

import debug from 'utils/debug';
import { NODE_STATUSES } from 'utils/constants';
import moment from 'moment';

const D2 = debug('m:CommuteOffer:saga:addBookingToRoute');

const isVehicleReadOnly = (currentOffer, vehicleId) =>
  D2.S.FUNCTION('isVehicleReadOnly', { currentOffer, vehicleId }, ({ $D2 }) => {
    const vehicle = currentOffer.stateless_api_request_data.vehicles.find(
      x => x.agent_id === vehicleId
    );
    $D2.S.INFO('vehicle', { vehicle, vehicleId });
    return (
      !vehicle || vehicle.readOnly || vehicle.readonly || vehicle.isReadOnly
    );
  });

export const addBookingToRoute0 = async (currentOffer, bookingId, vehicleId) =>
  D2.A.FUNCTION(
    'Method0',
    { currentOffer, bookingId, vehicleId },
    async ({ $D2 }) => {
      const { assigned_bookings, rejected_bookings, vehicles } =
        currentOffer.result;

      if (isVehicleReadOnly(currentOffer, vehicleId)) {
        // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
        return null;
      }

      const rejectedBookingIdx = rejected_bookings.findIndex(
        item => item.uid === bookingId
      );

      const currentBooking = rejected_bookings[rejectedBookingIdx];

      const { nodes } = currentOffer.stateless_api_request_data;

      const bookingNodes = nodes.filter(
        item => item.booking_uid === currentBooking.uid
      );

      const pickupNode = bookingNodes.find(
        item => item.node_type === 'pickup'
      );
      const dropoffNode = bookingNodes.find(
        item => item.node_type === 'dropoff'
      );

      const newAssignedBooking = {
        uid: currentBooking.uid,
        assigned_vehicle_id: vehicleId,
        scheduled_pickup_time: pickupNode.open_time_ts,
        scheduled_pickup_stop_id: pickupNode.stop_id,
        scheduled_dropoff_time: dropoffNode.open_time_ts,
        scheduled_dropoff_stop_id: dropoffNode.stop_id,
      };

      const result = {
        assigned_bookings: [...assigned_bookings, newAssignedBooking],
        rejected_bookings: rejected_bookings
          .slice(0, rejectedBookingIdx)
          .concat(
            rejected_bookings.slice(
              rejectedBookingIdx + 1,
              rejected_bookings.length
            )
          ),
      };
      
      const requestVehicles = currentOffer.stateless_api_request_data.vehicles.map(vehicle => {
        if (vehicle.agent_id === vehicleId) {
          return {
            ...vehicle,
            last_assignment_ts: moment().format(),
          };
        }
        return vehicle;
      });
      $D2.S.C.INFO('[105] requestVehicles', { requestVehicles });  
      
      const resultOffer = {
        ...currentOffer,
        stateless_api_request_data: {
          ...currentOffer.stateless_api_request_data,
          vehicles: requestVehicles
        },
        result: {
          ...result,
          vehicles: {
            ...vehicles,
            [vehicleId]: [
              ...vehicles[vehicleId],
              { ...pickupNode, scheduled_ts: pickupNode.open_time_ts },
              { ...dropoffNode, scheduled_ts: dropoffNode.open_time_ts },
            ],
          },
        },
      };
      $D2.S.INFO('newOffer', { newOffer });

      const editableBookingId = bookingId;

      const newOffer = await normalizeCommuteOffer(resultOffer);

      return { newOffer, editableBookingId };
    }
  );

export const addBookingToRouteWithoutStatelessAPI = async (
  currentOffer,
  bookingId,
  vehicleId,
  vehicleSegments
) =>
  D2.A.C.FUNCTION(
    'addBookingToRouteWithoutStatelessAPI',
    { currentOffer, bookingId, vehicleId, vehicleSegments },
    async ({ $D2 }) => {
      const { assigned_bookings, rejected_bookings, vehicles } =
        currentOffer.result;

      if (isVehicleReadOnly(currentOffer, vehicleId)) {
        // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
        return null;
      }

      $D2.S.INFO('vehicleSegments', { vehicleSegments });

      const rejectedBookingIdx = rejected_bookings.findIndex(
        item => item.uid === bookingId
      );
      $D2.S.INFO('rejectedBookingIdx', { rejectedBookingIdx });

      const currentBooking = rejected_bookings[rejectedBookingIdx];
      $D2.S.INFO('currentBooking', { currentBooking, rejectedBookingIdx });

      const { nodes } = currentOffer.stateless_api_request_data;
      $D2.S.INFO('nodes', { nodes });

      const bookingNodes = nodes.filter(
        item => item.booking_uid === currentBooking.uid
      );
      $D2.S.INFO('bookingNodes', { bookingNodes, currentBooking, nodes });

      const nodesOfVehicle = vehicles[vehicleId] || [];
      $D2.S.INFO('nodesOfVehicle', { nodesOfVehicle });

      const resultVehicle = nodesOfVehicle.map(node => ({
        ...node,
        h3: node.h3 || geoToH3(node.lat, node.lon, 13),
      }));
      $D2.S.INFO('resultVehicle', { resultVehicle });

      const vehicleLegsInfo = vehicleSegments.features.reduce(
        (memo, item) => {
          const index = memo.index + item.properties.nodesCount;
          return {
            legs: [...memo.legs, { index }],
            index,
          };
        },
        { legs: [], index: 0 }
      );
      $D2.S.INFO('vehicleLegsInfo', { vehicleLegsInfo, vehicleSegments });

      const nodeIndexForSegment = (position, nodeType) => {
        try {
          const legIndex = Math.min(
            position + 1,
            vehicleLegsInfo.legs.length - 1
          );
          return vehicleLegsInfo.legs[legIndex].index;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };

      if (resultVehicle.length < 2) {
        const pickupNode = bookingNodes.find(
          item => item.node_type === 'pickup'
        );
        const dropoffNode = bookingNodes.find(
          item => item.node_type === 'dropoff'
        );
        const newAssignedBooking = {
          uid: currentBooking.uid,
          assigned_vehicle_id: vehicleId,
          scheduled_pickup_time: pickupNode.open_time_ts,
          scheduled_pickup_stop_id: pickupNode.stop_id,
          scheduled_dropoff_time: dropoffNode.open_time_ts,
          scheduled_dropoff_stop_id: dropoffNode.stop_id,
        };
        $D2.S.INFO('newAssignedBooking', {
          newAssignedBooking,
          pickupNode,
          dropoffNode,
        });

        const result = {
          assigned_bookings: [...assigned_bookings, newAssignedBooking],
          rejected_bookings: rejected_bookings
            .slice(0, rejectedBookingIdx)
            .concat(
              rejected_bookings.slice(
                rejectedBookingIdx + 1,
                rejected_bookings.length
              )
            ),
        };
        $D2.S.INFO('result', { result });
        
        $D2.S.C.INFO('[226] vehicleId', { vehicleId });
        const requestVehicles = currentOffer.stateless_api_request_data.vehicles.map(vehicle => {
          if (vehicle.agent_id === vehicleId) {
            return {
              ...vehicle,
              last_assignment_ts: moment().format(),
            };
          }
          return vehicle;
        });
        $D2.S.C.INFO('[226] requestVehicles', { requestVehicles });

        const newOffer = {
          ...currentOffer,
          stateless_api_request_data: {
            ...currentOffer.stateless_api_request_data,
            vehicles: requestVehicles
          },
          result: {
            ...result,
            vehicles: {
              ...vehicles,
              [vehicleId]: [
                ...resultVehicle,
                { ...pickupNode, scheduled_ts: pickupNode.open_time_ts },
                { ...dropoffNode, scheduled_ts: dropoffNode.open_time_ts },
              ],
            },
          },
        };
        $D2.S.INFO('newOffer', { newOffer });

        const editableBookingId = bookingId;

        return { newOffer, editableBookingId };
      }
      const sortedResultVehicle = resultVehicle.sort(
        (a, b) => b.partial_route_index - a.partial_route_index
      );

      const completedNodes = resultVehicle.filter((x) => {
        if (x.status === 'completed') {
          return x;
        }
      });
      const completingStop = completedNodes.length
        ? resultVehicle[completedNodes.length]
        : {};

      const uncompletedResultVehicle = sortedResultVehicle.slice(
        completedNodes.length
      );

      const bookingNodeStopIDSet = new Set(
        bookingNodes.map(x => String(x.stop_id))
      );

      const bookingNodeH3Set = new Set(bookingNodes.map(x => x.h3));

      const existingNodes = uncompletedResultVehicle.filter(
        node =>
          (bookingNodeStopIDSet.has(String(node.stop_id)) ||
            bookingNodeH3Set.has(node.h3)) &&
          completingStop.id !== node.id
      );

      $D2.S.INFO('existingNodes', { existingNodes });
      const pickupBookingNode = bookingNodes.find(
        node => node.node_type === 'pickup'
      );
      const existingPickupNode = existingNodes.find(
        x =>
          x.node_type === 'pickup' &&
          (x.h3 === pickupBookingNode.h3 ||
            x.stop_id === pickupBookingNode.stop_id)
      );

      $D2.S.INFO('existingPickupNode', { existingPickupNode, existingNodes });
      const dropoffBookingNode = bookingNodes.find(
        node => node.node_type === 'dropoff'
      );
      const existingDropoffNode = existingNodes.find(
        x =>
          x.node_type === 'dropoff' &&
          (x.h3 === dropoffBookingNode.h3 ||
            x.stop_id === dropoffBookingNode.stop_id)
      );
      $D2.S.INFO('existingDropoffNode', { existingDropoffNode, existingNodes });
      const uncompletedVehicleSegments = vehicleSegments.features.filter(
        (segment) => {
          if (segment.properties.status !== 'completed') {
            if (
              segment.properties.partialRouteIndex !== 1 &&
              segment.properties.nodeType !== 'point'
            ) {
              return segment;
            }
          }
        }
      );

      const nearestNodes =
        // (!existingPickupNode || !existingDropoffNode) &&
        bookingNodes.reduce(
          (acc, node) => {
            $D2.S.INFO('bookingNodes.reduce:node', { node });
            const { lon, lat, node_type } = node;
            const { nearest, distance } = nearestFeatureEx(
              point([lon, lat], { node_type }),
              completedNodes.length
                ? {
                    features: uncompletedVehicleSegments,
                    type: vehicleSegments.type,
                  }
                : vehicleSegments
            );
            const accDistance = acc[node_type].distance;
            $D2.S.INFO('bookingNodes.reduce:nearest', {
              node,
              nearest,
              distance,
              accDistance,
            });
            if (distance < accDistance) {
              return $D2.S.V(
                'bookingNodes.reduce:selected',
                { node, nearest, distance },
                {
                  ...acc,
                  [node_type]: {
                    node,
                    nearest,
                    distance,
                  },
                }
              );
            }
            return $D2.S.V(
              'bookingNodes.reduce:skipped',
              { node, nearest, distance },
              acc
            );
          },
          {
            pickup: { node: null, nearest: null, distance: Infinity },
            dropoff: { node: null, nearest: null, distance: Infinity },
          }
        );

      $D2.S.INFO('nearestNodes', { nearestNodes });
      const pickupNodeInfo = existingPickupNode
        ? {
            node: existingPickupNode,
            position: resultVehicle.findIndex(
              item => existingPickupNode.id === item.id
            ),
            h3: existingPickupNode.h3,
            stop_id: existingPickupNode.stop_id,
          }
        : {
            node: nearestNodes.pickup.node,
            position: nodeIndexForSegment(
              nearestNodes.pickup.nearest.properties.position,
              'pickup'
            ),
            nearestPosition: nearestNodes.pickup.nearest.properties.position,
          };
      $D2.S.INFO('pickupNodeInfo', { pickupNodeInfo, existingPickupNode });

      const pickupNode = pickupNodeInfo.node;
      $D2.S.INFO('pickupNode', { pickupNode, pickupNodeInfo });

      const dropoffNodeInfo = existingDropoffNode
        ? {
            node: existingDropoffNode,
            position: resultVehicle.findIndex(
              item => existingDropoffNode.id === item.id
            ),
            h3: existingDropoffNode.h3,
            stop_id: existingDropoffNode.stop_id,
          }
        : {
            node: nearestNodes.dropoff.node,
            position: nodeIndexForSegment(
              nearestNodes.dropoff.nearest.properties.position,
              'dropoff'
            ),
            nearestPosition: nearestNodes.dropoff.nearest.properties.position,
          };
      $D2.S.INFO('dropoffNodeInfo', { dropoffNodeInfo, existingDropoffNode });

      const dropoffNode = dropoffNodeInfo.node;
      $D2.S.INFO('dropoffNode', { dropoffNode, dropoffNodeInfo });

      const newAssignedBooking = {
        uid: currentBooking.uid,
        assigned_vehicle_id: vehicleId,
        scheduled_pickup_time: pickupNode.open_time_ts,
        scheduled_pickup_stop_id: pickupNode.stop_id,
        scheduled_dropoff_time: dropoffNode.open_time_ts,
        scheduled_dropoff_stop_id: dropoffNode.stop_id,
      };
      $D2.S.INFO('newAssignedBooking', {
        newAssignedBooking,
        pickupNodeInfo,
        existingPickupNode,
        dropoffNodeInfo,
        existingDropoffNode,
        existingNodes,
        bookingNodes,
        vehicleLegsInfo,
      });

      const pickUpIndex = pickupNodeInfo.position;
      $D2.S.INFO('pickUpIndex', { pickUpIndex });

      const dropoffIndex = dropoffNodeInfo.position;
      $D2.S.INFO('dropoffIndex', { dropoffIndex });

      const newPickUpNode = {
        ...pickupBookingNode,
        status: NODE_STATUSES.ASSIGNED,
        booking_uid: currentBooking.uid,
        scheduled_ts: newAssignedBooking.scheduled_pickup_time,
        node_type: 'pickup',
      };
      $D2.S.INFO('newPickUpNode', { newPickUpNode });

      const newDropOffNode = {
        ...dropoffBookingNode,
        status: NODE_STATUSES.ASSIGNED,
        booking_uid: currentBooking.uid,
        scheduled_ts: newAssignedBooking.scheduled_dropoff_time,
        node_type: 'dropoff',
      };
      $D2.S.INFO('newDropOffNode', { newDropOffNode });

      const result = {
        assigned_bookings: [...assigned_bookings, newAssignedBooking],
        rejected_bookings: rejected_bookings
          .slice(0, rejectedBookingIdx)
          .concat(
            rejected_bookings.slice(
              rejectedBookingIdx + 1,
              rejected_bookings.length
            )
          ),
      };
      $D2.S.INFO('result', { result });

      if (pickUpIndex !== -1 && dropoffIndex !== -1) {
        if (pickUpIndex <= dropoffIndex) {
          const requestVehicles = currentOffer.stateless_api_request_data.vehicles.map(vehicle => {
            if (vehicle.agent_id === vehicleId) {
              return {
                ...vehicle,
                last_assignment_ts: moment().format(),
              };
            }
            return vehicle;
          });
          $D2.S.C.INFO('[487] requestVehicles', { requestVehicles });          
          const finalValue = {
            newOffer: {
              ...currentOffer,
              stateless_api_request_data: {
                ...currentOffer.stateless_api_request_data,
                vehicles: requestVehicles,
              },
              result: {
                ...result,
                vehicles: {
                  ...vehicles,
                  [vehicleId]: resultVehicle
                    .slice(0, pickUpIndex)
                    .concat([newPickUpNode])
                    .concat(resultVehicle.slice(pickUpIndex, dropoffIndex))
                    .concat([newDropOffNode])
                    .concat(resultVehicle.slice(dropoffIndex)),
                },
              },
            },
            editableBookingId: currentBooking.uid,
          };
          return finalValue;
        }
        const message = 'The vehicle goes in the opposite direction.';
        global.openInfoMessage(message, { title: 'Warning' });
        throw new Error(message);
      }
      
      $D2.S.C.INFO('[503] vehicleId', { vehicleId });
      const requestVehicles = currentOffer.stateless_api_request_data.vehicles.map(vehicle => {
        if (vehicle.agent_id === vehicleId) {
          return {
            ...vehicle,
            last_assignment_ts: moment().format(),
          };
        }
        return vehicle;
      });
      $D2.S.C.INFO('[503] requestVehicles', { requestVehicles });

      const resultOffer = {
        ...currentOffer,
        stateless_api_request_data: {
          ...currentOffer.stateless_api_request_data,
          vehicles: requestVehicles,
        },
        result: {
          ...result,
          vehicles: {
            ...vehicles,
            [vehicleId]: [...resultVehicle, newPickUpNode, newDropOffNode],
          },
        },
      };
      console.log('*** [503] resultOffer', resultOffer);

      const editableBookingId = bookingId;

      const newOffer = await normalizeCommuteOffer(resultOffer);

      return { newOffer, editableBookingId };
    }
  );

export const addBookingToRouteWithStatelessAPI = async (
  currentOffer,
  bookingId,
  vehicleId
) =>
  D2.A.FUNCTION(
    'Method2',
    { currentOffer, bookingId, vehicleId },
    async ({ $D2 }) => {
      if (isVehicleReadOnly(currentOffer, vehicleId)) {
        // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
        return null;
      }

      const filteredVehiclesOffer = commuteOffer$FilterVehicles(currentOffer, [
        vehicleId,
      ]);
      $D2.S.INFO('filteredVehiclesOffer', {
        filteredVehiclesOffer,
        currentOffer,
        vehicleId,
      });

      const vehicleAssignedBookings =
        filteredVehiclesOffer.result.assigned_bookings.filter(
          booking => booking.assigned_vehicle_id === vehicleId
        );
      $D2.S.INFO('vehicleAssignedBookings', {
        vehicleAssignedBookings,
        vehicleId,
      });

      const vehicleAssignedBookingsList = [
        ...vehicleAssignedBookings.map(booking => booking.uid),
        bookingId,
      ];
      $D2.S.INFO('vehicleAssignedBookingsList', {
        vehicleAssignedBookingsList,
        vehicleId,
      });

      const vehicleAssignedBookingsSet = new Set(vehicleAssignedBookingsList);

      const filteredVehiclesOfferWithBookings = {
        ...filteredVehiclesOffer,
        stateless_api_request_data: {
          ...filteredVehiclesOffer.stateless_api_request_data,
          bookings: Object.keys(
            currentOffer.stateless_api_request_data.bookings
          ).reduce((memo, requestBookingId) => {
            const booking =
              currentOffer.stateless_api_request_data.bookings[
                requestBookingId
              ];
            if (vehicleAssignedBookingsSet.has(requestBookingId)) {
              return {
                ...memo,
                [requestBookingId]: booking,
              };
            }
            return memo;
          }, {}),
          nodes: currentOffer.stateless_api_request_data.nodes.filter(node =>
            vehicleAssignedBookingsSet.has(node.booking_uid)
          ),
        },
        result: {
          ...filteredVehiclesOffer.result,
          assigned_bookings: vehicleAssignedBookings,
          rejected_bookings: [],
        },
      };
      $D2.S.INFO('filteredVehiclesOfferWithBookings', {
        filteredVehiclesOfferWithBookings,
        filteredVehiclesOffer,
        vehicleAssignedBookingsList,
        bookingId,
      });

      const requestOffer = {
        ...filteredVehiclesOfferWithBookings,
        stateless_api_request_data: {
          ...filteredVehiclesOfferWithBookings.stateless_api_request_data,
          engine_settings: {
            ...filteredVehiclesOfferWithBookings.stateless_api_request_data
              .engine_settings,
            solver_parameters: {
              ...filteredVehiclesOfferWithBookings.stateless_api_request_data
                .engine_settings.solver_parameters,
              time_limit_ms: 5000,
            },
          },
        },
      };

      const requestOffers = [requestOffer];
      $D2.S.INFO('requestOffers', {
        requestOffers,
      });
      const calculatedOffers = await api.scheduleCommuteOffers(requestOffers, {
        ignoreErrors: false,
        setProgress: () => {},
      });
      $D2.S.INFO('calculatedOffers', {
        calculatedOffers,
        requestOffers,
      });

      const calculatedOffer = calculatedOffers[0];

      const resultOffer = {
        ...currentOffer,
        result: {
          ...currentOffer.result,
          assigned_bookings: [
            ...currentOffer.result.assigned_bookings.filter(
              booking => !vehicleAssignedBookingsSet.has(booking.uid)
            ),
            ...calculatedOffer.result.assigned_bookings,
          ],
          rejected_bookings: [
            ...currentOffer.result.rejected_bookings.filter(
              booking => !vehicleAssignedBookingsSet.has(booking.uid)
            ),
            ...calculatedOffer.result.rejected_bookings,
          ],
          vehicles: {
            ...currentOffer.result.vehicles,
            ...calculatedOffer.result.vehicles,
          },
        },
      };

      const newOffer = await normalizeCommuteOffer(resultOffer);

      return { newOffer };
    }
  );

export function* addBookingToRouteHandler({ payload }) {
  D2.S.INFO('Handler:Request', payload);

  const { bookingId, vehicleId } = payload;

  const currentProjectConfig = yield select(currentProjectConfigSelector);

  const currentOffer = yield select(commuteOfferCurrentDataSelector);

  try {
    if (isVehicleReadOnly(currentOffer, vehicleId)) {
      const message = 'Vehicle is read-only';
      global.openInfoMessage(message, { title: 'Error' });
      const error = new Error(message);
      yield put({ type: actions.ADD_BOOKING_TO_ROUTE_FAILURE, payload: error });
      return;
    }

    const allSegments = yield select(detailedRouteSourceSelector);
    D2.S.INFO('Handler:allSegments', { allSegments });

    const vehicleSegments = {
      type: 'FeatureCollection',
      features: allSegments.features.filter(route =>
        D2.S.V(
          'Handler:allSegments.features:route',
          { route },
          route.properties.agent_id === vehicleId
        )
      ),
    };
    D2.S.INFO('Handler:vehicleSegments', vehicleSegments);

    const { use_solver_for_booking_assignments = false } = {
      ...(currentProjectConfig?.logistics ?? {}),
      ...(currentProjectConfig?.logistics_settings ?? {}),
      ...(currentProjectConfig?.logistics_api_settings ?? {}),
      ...(currentOffer.stateless_api_request_data.logistics ?? {}),
      ...(currentOffer.stateless_api_request_data.logistics_settings ?? {}),
      ...(currentOffer.stateless_api_request_data.logistics_api_settings ?? {}),
    };

    const calculationMethod = use_solver_for_booking_assignments
      ? addBookingToRouteWithStatelessAPI
      : addBookingToRouteWithoutStatelessAPI;

    const { newOffer } = yield call(
      calculationMethod,
      currentOffer,
      bookingId,
      vehicleId,
      vehicleSegments
    );
    D2.S.INFO('Handler:newOffer', { newOffer });

    D2.S.INFO('Handler:Success', {
      newOffer,
      currentOffer,
      bookingId,
      vehicleId,
    });
    yield put({
      type: actions.ADD_BOOKING_TO_ROUTE_SUCCESS,
      payload: { newOffer, currentOffer, bookingId, vehicleId },
    });

    if (newOffer) {
      const vehicles = yield select(allVehiclesSelector);
      const vehicle = vehicles.find(item => item.agent_id === vehicleId);

      yield put(actions.fetchRoute(vehicle));
      yield take(actions.ROUTE_FETCH_RESULTS);

      yield put(actions.recalculateVehicleTime([vehicleId], 'set'));
    }
    D2.S.INFO('Handler:Success', { newOffer });
    yield sleep(1000);
  } catch (error) {
    D2.S.C.INFO('Handler:Failed', { error });
    // eslint-disable-next-line
    console.log(error);
    try {
      const { newOffer } = yield call(
        addBookingToRoute0,
        currentOffer,
        bookingId,
        vehicleId
      );

      D2.S.INFO('Handler:Success', {
        newOffer,
        currentOffer,
        bookingId,
        vehicleId,
      });
      yield put({
        type: actions.ADD_BOOKING_TO_ROUTE_SUCCESS,
        payload: { newOffer, currentOffer, bookingId, vehicleId },
      });

      if (newOffer) {
        const vehicles = yield select(allVehiclesSelector);
        const vehicle = vehicles.find(item => item.agent_id === vehicleId);

        yield put(actions.fetchRoute(vehicle));
        yield take(actions.ROUTE_FETCH_RESULTS);

        yield put(actions.recalculateVehicleTime([vehicleId], 'set'));
      }
      yield sleep(1000);
    } catch (e2) {
      // eslint-disable-next-line
      console.log(e2);
      D2.S.INFO('Handler:Failure', { error: e2 });
      yield put({ type: actions.ADD_BOOKING_TO_ROUTE_FAILURE, payload: e2 });
    }
  }
}
